@use '@/styles/utils/mixins.scss' as *;

.formsAndDocumentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    @include tab() {
      flex-direction: column;
    }
  }
  .sectionDescription {
    max-width: 29rem;
  }
  .documentsContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
  }
  .documentsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .documentItem {
    background-color: $white;
    border-radius: 1.25rem;
    padding: 2.5rem 1.875rem;
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
    border: 0.063rem solid $white;
    cursor: pointer;
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    transition: all 0.2s ease-in-out;
    color: $dark;

    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $primaryAlt;
      -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
      -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
      box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    }

    .documentParagraph {
      color: $neutral08;
    }
  }
}
